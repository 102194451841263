import React from 'react';
import Layout from '../../components/Layout';
import BreadcrumbSchema from '../../components/BreadcrumbSchema';
import Breadcrumbs from '../../components/Breadcrumbs';
import { Helmet } from 'react-helmet';
import {
  FaLaptopCode,
  FaRedo,
  FaShoppingCart,
  FaCogs,
  FaSearch,
  FaChartLine,
  FaEdit,
  FaServer,
  FaCameraRetro,
  FaBullhorn,
  FaPenNib,
  FaExclamationTriangle,
  FaSearchPlus,
  FaTasks,
  FaChartBar,
  FaCode,
  FaLightbulb,
} from 'react-icons/fa';

const seoData = {
  title: 'サービス一覧｜奈良のホームページ制作なら｜インヴォルブ',
  description:
    'インヴォルブが提供するサービス一覧。ホームページ制作、SEO対策、ECサイト構築、Web広告運用など幅広く対応。',
  pageUrl: 'https://www.involve-in.jp/service/',
  ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
};

// ⭐ パンくずリスト設定
const breadcrumbList = [
  { name: 'ホーム', url: 'https://www.involve-in.jp/' },
  { name: 'サービスについて', url: 'https://www.involve-in.jp/service' },
];

const categories = [
  {
    title: 'ホームページ制作・システム構築',
    id: 'creative',
    services: [
      {
        title: 'ホームページ制作',
        url: '/service/new',
        icon: <FaLaptopCode />,
        description: '新しくホームページを制作したい方へ。',
      },
      {
        title: 'ホームページリニューアル',
        url: '/service/renewal',
        icon: <FaRedo />,
        description: '既存のサイトを改善・最適化します。',
      },
      {
        title: 'ECサイト構築',
        url: '/service/ec',
        icon: <FaShoppingCart />,
        description: 'ネットショップの開発・運営支援。',
      },
      {
        title: 'CMSカスタマイズ',
        url: '/service/cms',
        icon: <FaCogs />,
        description: 'WordPress・ヘッドレスCMSの構築・最適化。',
      },
      {
        title: 'Webページコーディング開発代行',
        url: '/service/coding',
        icon: <FaCode />,
        description:
          'デザインはできるがコーディングができない方向けの代行サービス。',
      },
      {
        title: '動画制作・写真撮影',
        url: '/service/media',
        icon: <FaCameraRetro />,
        description: 'サイト向けのプロフェッショナルな映像制作。',
      },
    ],
  },
  {
    title: 'ホームページの運用管理',
    id: 'management',
    services: [
      {
        title: 'ホームページの更新代行',
        url: '/service/update',
        icon: <FaEdit />,
        description: 'サイト運営の手間を削減し、常に最新の状態に。',
      },
      {
        title: 'サイト運営代行',
        url: '/service/management',
        icon: <FaTasks />,
        description: 'サイトの更新や運営をお任せ。',
      },
      {
        title: '急なトラブル対応',
        url: '/service/troubleshooting',
        icon: <FaExclamationTriangle />,
        description: 'サイトの不具合や緊急トラブルを迅速に解決。',
      },
      {
        title: 'サイト調査（サイト診断）',
        url: '/service/audit',
        icon: <FaSearchPlus />,
        description: 'サイトの問題点を診断し、改善提案を提供。',
      },
      {
        title: 'ドメイン・サーバー管理代行',
        url: '/service/server-management',
        icon: <FaServer />,
        description: 'サーバー・ドメインの契約・管理を代行。',
      },
    ],
  },
  {
    title: 'Webマーケティング',
    id: 'marketing',
    services: [
      {
        title: 'SEO対策',
        url: '/service/seo',
        icon: <FaSearch />,
        description: '検索順位を上げて集客力を向上。',
      },
      {
        title: 'Webコンサルティング',
        url: '/service/consulting',
        icon: <FaChartLine />,
        description: 'Webマーケティング戦略の提案・改善。',
      },
      {
        title: 'Web広告運用',
        url: '/service/ads',
        icon: <FaBullhorn />,
        description: 'Google・SNS広告を最適化し、集客効果を最大化。',
      },
      {
        title: '文章作成（ライティング）',
        url: '/service/writing',
        icon: <FaPenNib />,
        description: 'SEOに強い文章を作成。',
      },
      {
        title: 'アクセス解析・改善提案',
        url: '/service/analytics',
        icon: <FaChartBar />,
        description: 'サイトのデータを分析し、効果的な改善策を提案。',
      },
      {
        title: 'ウェブコンテンツ企画立案協力',
        url: '/service/planning',
        icon: <FaLightbulb />,
        description: 'ウェブの知識が不足していても最適な企画をサポート。',
      },
    ],
  },
];

const ServiceIndex = () => {
  return (
    <Layout>
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <Helmet>
        <title>{seoData.title}</title>
        <meta name="description" content={seoData.description} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={seoData.pageUrl} />
        <link
          rel="icon"
          type="image/x-icon"
          href="/images/common/favicon.ico"
        />

        {/* Open Graph (OGP) */}
        <meta property="og:title" content={seoData.title} />
        <meta property="og:description" content={seoData.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.pageUrl} />
        <meta property="og:image" content={seoData.ogImage} />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seoData.title} />
        <meta name="twitter:description" content={seoData.description} />
        <meta name="twitter:image" content={seoData.ogImage} />
      </Helmet>

      <div className="max-w-screen-xl mx-auto bg-white opacity-90 px-8 py-20">
        <h1 className="font-roboto text-6xl md:text-[10rem] text-center font-bold mb-12">
          Service
        </h1>
        <p className="text-2xl text-gray-600 text-center mb-12">
          インヴォルブが提供するサービス一覧です。ご希望のサービスをお選びください。
        </p>

        {/* 各カテゴリごとにリスト表示 */}
        {categories.map((category, catIndex) => (
          <div key={catIndex} id={category.id} className="mb-16">
            <h2 className="text-4xl font-bold text-black mb-6 border-b-4 border-gray-300 pb-2">
              {category.title}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {category.services.map((service, index) => (
                <a
                  key={index}
                  href={service.url}
                  className="block p-6 bg-white rounded-xl shadow-lg hover:shadow-2xl transition transform hover:-translate-y-1"
                >
                  <div className="flex items-center space-x-4">
                    <div className="text-4xl text-gray-900">{service.icon}</div>
                    <h3 className="text-2xl font-bold text-black">
                      {service.title}
                    </h3>
                  </div>
                  <p className="text-2xl text-gray-600 mt-2">
                    {service.description}
                  </p>
                </a>
              ))}
            </div>
          </div>
        ))}
      </div>
      <Breadcrumbs breadcrumbList={breadcrumbList} />
    </Layout>
  );
};

export default ServiceIndex;
